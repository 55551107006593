<template>
  <div>
    <a-drawer
      title="预缴-明细"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '80px' }"
      @close="close"
    >
      <div class="drawer-content">
        基本信息
        <a-divider></a-divider>
        <searchForm
          :formItem="formItem"
          @getSearch="search($event)"
        ></searchForm>
        <a-table
          :columns="columns"
          :data-source="tableData"
          :pagination="pagination"
          @change="handleTableChange"
          :row-key="
            (record, index) => {
              return record.id;
            }
          "
        >
        </a-table>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="close">
          关闭
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { formItem, columns, pagination } from "./list";
import {
	rechargeList,
} from "@/api/payment/prepayManage";
export default {
	props: {
		id: Number,
		show: Boolean,
	},
	data() {
		return {
			formItem,
			columns,
			pagination,
			selectedRowKeys: [],
			tableData:[],
			searchForm:{
				estateId: undefined,
				code: undefined,
				createDateStart: undefined,
				createDateEnd: undefined,
			}
		};
	},
	methods: {
		async getData() {
			let res = await rechargeList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				...this.searchForm,
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total;
			this.selectedRowKeys = [];
		},
		search(data){
			this.searchForm = data;
			this.getData()
		},
		selectionChoosed() {},
		close(){
			this.$emit('close');
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
	},
	watch: {
		id: {
			handler(id) {
				// 没有id不获取
				if(id!== null && id!== undefined){
					this.searchForm.estateId = id
					this.getData()
				}
			},
			immediate: true,
		},
	},
};
</script>

<style></style>
