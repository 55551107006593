export const formItem = [
	{
		type: 'input',
		label:'流水号',
		prop:'code',
		placeholder:'请输入'
	},
	{
		type: 'time',
		label:'创建时间',
		start: 'createDateStart',
		end:'createDateEnd'
	},
]
export const columns = [
	{
		title: "充值金额",
		dataIndex: "amount",
	},
	{
		title: "流水号",
		dataIndex: "code",
	},
	{
		title: "创建时间",
		dataIndex: "createDate",
	},
	{
		title: "创建人",
		dataIndex: "createName",
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}