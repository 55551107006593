import httpService from "@/request"

// 预缴列表
export function prepaymentList(params) {
	return httpService({
		url: `/user/prepayment/list`,
		method: 'get',
		params: params,
	})
}
export function rechargeList(params) {
	return httpService({
		url: `/user/prepayment/rechargeList`,
		method: 'get',
		params: params,
	})
}
export function recharge(params) {
	return httpService({
		url: `/user/prepayment/recharge`,
		method: 'post',
		data: params,
	})
}
