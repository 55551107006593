import httpService from "@/request"

// 房产 楼栋
// 楼栋列表
export function buildingList(params) {
	return httpService({
		url: `/user/building/list`,
		method: 'get',
		params: params,
	})
}
// 楼栋新增
export function buildInsert(params) {
	return httpService({
		url: `/user/building/insert`,
		method: 'post',
		data: params,
	})
}
// 楼栋详情
export function buildInfo(params) {
	return httpService({
		url: `/user/building/findById`,
		method: 'get',
		params: params,
	})
}
// 楼栋修改
export function buildUpdate(params) {
	return httpService({
		url: `/user/building/updateBuilding`,
		method: 'post',
		data: params,
	})
}
// 楼栋删除
export function buildingDel(params) {
	return httpService({
		url: `/user/building/deleteBuilding`,
		method: 'post',
		data: params,
	})
}
// 全部楼栋
export function allBuilding(params) {
	return httpService({
		url: `/user/building/findAllBuilding`,
		method: 'get',
		params: params,
	})
}
// 楼栋查单元
export function findByBuildingId(params) {
	return httpService({
		url: `/user/unit/findByBuildingId`,
		method: 'get',
		params: params,
	})
}
/**
 * 单元
 * list update del
 */
export function unitAdd(params) {
	return httpService({
		url: `/user/unit/insertUnit`,
		method: 'post',
		data: params,
	})
}
export function unitUpdate(params) {
	return httpService({
		url: `/user/unit/updateUnit`,
		method: 'post',
		data: params,
	})
}
export function unitDel(params) {
	return httpService({
		url: `/user/unit/deleteUnit`,
		method: 'post',
		data: params,
	})
}
// 房屋
// 房屋列表
export function houseList(params) {
	return httpService({
		url: `/user/estate/list`,
		method: 'get',
		params: params,
	})
}
// 房屋新增
export function houseInsert(params) {
	return httpService({
		url: `/user/estate/insert`,
		method: 'post',
		data: params,
	})
}
// 房屋修改
export function houseInfo(params) {
	return httpService({
		url: `/user/estate/findById`,
		method: 'get',
		params: params,
	})
}
// 房屋修改
export function houseUpdate(params) {
	return httpService({
		url: `/user/estate/update`,
		method: 'post',
		data: params,
	})
}
// 房屋删除
export function houseDel(params) {
	return httpService({
		url: `/user/estate/delete`,
		method: 'post',
		data: params,
	})
}
// 房屋租赁切换
export function isEnableLease(params) {
	return httpService({
		url: `/user/estate/isEnableLease`,
		method: 'get',
		params: params,
	})
}
// 房屋设置 房屋类型
export function estateType(params) {
	return httpService({
		url: `/user/estateType/list`,
		method: 'get',
		params: params,
	})
}
// 类型新增
export function estateTypeInsert(params) {
	return httpService({
		url: `/user/estateType/insert`,
		method: 'post',
		data: params,
	})
}
// 类型修改
export function estateTypeUpdate(params) {
	return httpService({
		url: `/user/estateType/update`,
		method: 'post',
		data: params,
	})
}
// 类型删除
export function estateTypeDel(params) {
	return httpService({
		url: `/user/estateType/delete`,
		method: 'post',
		data: params,
	})
}
// 房屋列表级联
export function estate(params) {
	return httpService({
		url: `/user/estate/findEstateCascade`,
		method: 'get',
		params: params,
	})
}

// 房屋列表级联
export function findEstateCascade(params) {
	return httpService({
		url: `/user/estate/findEstateCascade`,
		method: 'get',
		params: params,
	})
}