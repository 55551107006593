<template>
  <div>
    <div class="cardTitle">预缴管理</div>
    <searchForm :formItem="formItem" @getSearch="search($event)"></searchForm>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      @change="handleTableChange"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: selectionChoosed,
      }"
      :row-key="
        (record, index) => {
          return record.id;
        }
      "
    >
      <span slot="name" slot-scope="text, row">
        {{
          row.buildingName + "栋/" + row.unitName + "单元/" + row.name + "室"
        }}
      </span>
      <span slot="action" slot-scope="text, row">
        <a-space>
          <a class="ant-dropdown-link" @click="list(row.id)">明细</a>
          <a class="ant-dropdown-link" @click="recharge(row)">充值</a>
        </a-space>
      </span>
      <span slot="tags" slot-scope="text, row">
        <a-tag>{{
          row.status === 1 ? "充足" : row.status === 2 ? "不足" : "-"
        }}</a-tag>
      </span>
    </a-table>
    <div class="action">
      <a-dropdown :disabled="!hasSelected">
        <a-menu slot="overlay" @click="handleMenuClick">
          <!-- <a-menu-item key=""> 批量 </a-menu-item> -->
        </a-menu>
        <a-button> 批量操作 <a-icon type="down" /> </a-button>
      </a-dropdown>
      <span style="margin-left: 8px">
        <template v-if="hasSelected">
          {{ `已选择 ${selectedRowKeys.length} 条` }}
        </template>
      </span>
    </div>
    <!-- 预缴明细 -->
    <pre-list :show="drawer.show" :id="drawer.id" @close="close"></pre-list>
    <!-- 充值 -->
    <a-modal
      title="预缴充值"
      :visible="modal.show"
      @ok="rechargeOk"
      @cancel="rechargeCancel"
    >
      <a-input
        v-model.number="modal.amount"
        placeholder="请输入充值金额"
      ></a-input>
    </a-modal>
  </div>
</template>

<script>
import { formItem, columns, pagination } from "./depend/config";
import { prepaymentList, recharge } from "@/api/payment/prepayManage";
import { allBuilding } from "@/api/basic/estate";
import preList from "./depend/preList.vue";
export default {
	components: {
		preList,
	},
	data() {
		return {
			drawer: {
				show: false,
				id: undefined,
			},
			modal: {
				show: false,
				estateInfo: "",
				amount: undefined,
				id: undefined,
			},
			tableData: [],
			searchForm: {
				keyword: undefined,
				status: undefined,
				buildingId: undefined,
				// unitId: undefined,
				// estateId: undefined,
			},
			formItem,
			columns,
			pagination,
			selectedRowKeys: [],
		};
	},
	mounted() {
		this.getData();
		this.getSelect();
	},
	methods: {
		async getSelect() {
			// 获取楼栋 后续可加单元、房间筛选
			let res = await allBuilding();
			this.formItem[2].option = res.data;
		},
		search(data){
			this.searchForm = data;
			this.getData()
		},
		async getData() {
			let res = await prepaymentList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				...this.searchForm,
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total;
			this.selectedRowKeys = [];
		},
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		// 充值框
		recharge(row) {
			this.modal = {
				show: true,
				id:row.id,
				estateInfo:
          row.buildingName + "栋/" + row.unitName + "单元/" + row.name + "室",
			};
		},
		// 确认充值
		rechargeOk() {
			this.$confirm({
				title: "预缴充值",
				content: `是否给房屋${this.modal.estateInfo}充值`,
				onOk:()=>{
					recharge({ estateId: this.modal.id, amount: this.modal.amount }).then(
						(res) => {
							if (res.code === 200) {
								this.$message.success(res.msg);
								this.getData()
								this.rechargeCancel();
							} else {
								this.$message.error(res.msg);
							}
						}
					);
				},
			});
		},
		rechargeCancel() {
			this.modal = {
				show: false,
				estateInfo: "",
				amount: undefined,
				id: undefined,
			};
		},
		// 打开明细抽屉
		list(id) {
			this.drawer = {
				show: true,
				id: id,
			};
		},
		close() {
			this.drawer.show = false;
		},
		handleMenuClick(data) {
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>
<style lang="less" scoped>
.table-img {
  width: 40px;
  height: 40px;
}
</style>
