export const formItem = [
	{
		type: 'input',
		label:'关键字',
		prop:'keyword',
		placeholder:'请输入'
	},
	{
		type: 'select',
		label:'余量告警',
		prop:'status',
		placeholder: '请选择',
		option:[{ id:1,name:'充足'},{ id:2,name:'不足'}]
	},
	{
		type: 'select',
		label:'楼栋',
		prop:'buildingId',
		placeholder: '请选择楼栋',
		option:[]
	},
	// {
	// 	type: 'select',
	// 	label:'单元',
	// 	prop:'unitId',
	// 	placeholder: '请选择单元',
	// 	option:[]
	// },
	// {
	// 	type: 'select',
	// 	label:'房屋',
	// 	prop:'estateId',
	// 	placeholder: '请选择房屋',
	// 	option:[]
	// },
]
export const columns = [
	{
		title: "房屋名称",
		dataIndex: "manageBuildingName",
		scopedSlots: { customRender: "name" },
	},
	{
		title: "户主",
		dataIndex: "owner",
	},
	{
		title: "余额",
		dataIndex: "balance",
	},
	{
		title: "余额状态",
		dataIndex: "",
		scopedSlots:{ customRender: "tags"}
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}